import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../../components/header/Header";
import StartModal from "../../../components/modals/StartModal";
import "./Bookings.scss";

import LandingImage from "../../assets/img/illustration-placeholder.svg";
import { useHistory } from "react-router";
import { useCallback, useEffect, useState } from "react";
import DashHeader from "../../../components/dash-header/DashHeader";
import LeftMenu from "../../../components/left-menu/LeftMenu";

import moment from "moment";

import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridSortChangeEvent,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";

import { getServices } from "../../../services/services.service";
import {
  orderBy,
  SortDescriptor,
  DataResult,
  process,
  State,
} from "@progress/kendo-data-query";
import { workshop } from "../../../services/data.service";
import DashModal from "../../../components/modals/DashModal";

import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { viewBookings } from "../../../services/workshop.service";
import CustomSnackbar from "../../../components/snackbar/CustomSnackbar";

const initialDataState: State = {
  take: 10,
  skip: 0,
  group: [{ field: "service_status" }],
};

const processWithGroups = (data: any[], dataState: State) => {
  const newDataState = process(data, dataState);

  setGroupIds({ data: newDataState.data, group: dataState.group });

  return newDataState;
};

const Bookings: React.FC = () => {
  const [bookings, setBookings]: any = useState([]);
  const [loadingServices, setLoadingServices]: any = useState(true);
  const [showModal, setShowModal]: any = useState(false);
  const [modaldata, setModaldata]: any = useState(false);
  const [sort, setSort] = useState<Array<SortDescriptor>>([
    { field: "services_info.service_date", dir: "asc" },
  ]);

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [resultState, setResultState] = useState<DataResult>(
    processWithGroups([], initialDataState),
  );
  const [collapsedState, setCollapsedState] = useState<string[]>([]);
  const onDataStateChange = useCallback((event: GridDataStateChangeEvent) => {
    const newDataState = processWithGroups(bookings, event.dataState);

    setDataState(event.dataState);
    setResultState(newDataState);
  }, []);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onExpandChange = useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState],
  );

  console.log("Print the booking data: ", bookings);
  useEffect(() => {
    setLoadingServices(true);
    viewAllBookings();
    setLoadingServices(false);
  }, []);

  const viewAllBookings = () => {
    setLoadingServices(true);
    viewBookings().then((res: any) => {
      setBookings(res.bookings);
      setLoadingServices(false);
    });
  };

  const handleShowSnackbar = () => {
    viewAllBookings();
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const ShowModal = (data: any) => {
    setModaldata(data);
    setShowModal(true);
  };

  const StatusCell = (props: GridCellProps) => {
    let color = "";
    switch (props.dataItem[props.field || 0]) {
      case "Rejected":
        color = "red";
        break;
      case "pending":
        color = "blue";
        break;
      case "Completed":
        color = "green";
        break;
      case "In Progress":
        color = "orange";
        break;
      case "Accepted":
        color = "purple";
        break;
    }

    return (
      <td>
        <div
          style={{
            background: color,
            textAlign: "center",
            color: "white",
            width: "100%",
            height: "100%",
          }}
        >
          {props.dataItem[props.field || 0]}
        </div>
      </td>
    );
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <DashHeader />
          <div className="container-section">
            <LeftMenu />
            <div className="right-content-section">
              <div className="header-container">
                <h6>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      d="M8,0 C9.36170213,0 10.7477204,0.0972644377 11.9635258,0.267477204 C12.5957447,0.364741641 13.1306991,0.924012158 13.2036474,1.04559271 L14.1762918,3.8449848 L15.6352584,4.06382979 C15.8054711,4.0881459 16,4.23404255 16,4.40425532 L16,4.76899696 C16,4.93920973 15.8541033,5.08510638 15.6838906,5.08510638 L14.8328267,5.08510638 C15.0030395,5.23100304 15.7082067,5.71732523 15.7325228,7.39513678 L15.7325228,12.6018237 L15.7325228,12.6018237 C15.7325228,12.8693009 15.5379939,13.0881459 15.2705167,13.0881459 L13.1793313,13.0881459 C12.9361702,13.0881459 12.7173252,12.8693009 12.7173252,12.6018237 L12.7173252,11.0668693 L12.7173252,11.0668693 C11.1367781,11.1641337 9.58054711,11.212766 8,11.1884498 C6.41945289,11.212766 4.83890578,11.1641337 3.28267477,11.0668693 L3.28267477,12.6018237 L3.28267477,12.6018237 C3.28267477,12.8693009 3.06382979,13.0881459 2.82066869,13.0881459 L0.729483283,13.0881459 C0.462006079,13.0881459 0.243161094,12.8693009 0.243161094,12.6018237 C0.243161094,12.0243161 0.247382641,11.2689759 0.252307779,10.4822378 L0.258330519,9.53245314 C0.263255657,8.74402651 0.267477204,7.98277609 0.267477204,7.39513678 C0.291793313,5.71732523 0.996960486,5.23100304 1.14285714,5.08510638 L0.291793313,5.08510638 C0.145896657,5.08510638 0,4.93920973 0,4.76899696 L0,4.40425532 C0,4.23404255 0.194528875,4.0881459 0.364741641,4.06382979 L1.82370821,3.8449848 L2.79635258,1.04559271 C2.86930091,0.924012158 3.37993921,0.364741641 4.01215805,0.267477204 C5.25227964,0.0972644377 6.61398176,0 8,0 Z M5,7 L2,7 L2,9 L5,9 L5,7 Z M14,7 L11,7 L11,9 L14,9 L14,7 Z"
                      id="Combined-Shape"
                    ></path>
                  </svg>
                  <span>Bookings</span>
                </h6>
                {/* <div className="dropdown">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    type="button"
                  >
                    Next 7 Days 
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#">
                      Next 7 Days
                    </a>
                    <a className="dropdown-item" href="#">
                      Next 14 Days
                    </a>
                    <a className="dropdown-item" href="#">
                      Next 30 Days
                    </a>
                  </div>
                </div>*/}
              </div>
              <div className="content-container">
                {loadingServices ? (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                ) : bookings ? (
                  bookings.length > 0 ? (
                    <Grid
                      style={{ height: "100%" }}
                      sortable={false}
                      onRowClick={(e: any) => {
                        console.log(e);
                        ShowModal(e.dataItem);
                      }}
                      pageable={{ pageSizes: true }}
                      data={bookings}
                      total={resultState.total}
                      // groupable={true}
                      onDataStateChange={onDataStateChange}
                      {...dataState}
                      onExpandChange={onExpandChange}
                      expandField="expanded"
                    >
                      <Column field="_id" title="ID" />
                      <Column field="surname" title="Name" />
                      <Column field="vehicle_details.make" title="Make" />
                      <Column field="vehicle_details.model" title="Model" />
                      <Column field="vehicle_details.year" title="Year" />
                      <Column
                        field="registration_number"
                        title="Registration"
                      />
                      <Column field="vehicle_details.vin" title="Vin Number" />
                      <Column field="service_date" title="Service" />
                      <Column field="status" title="Status" cell={StatusCell} />
                    </Grid>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h6>No Bookings to show</h6>
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="k-loading-image" />
                  </div>
                )}
              </div>
            </div>
          </div>
          {showModal ? (
            <DashModal
              setLoadingServices={setLoadingServices}
              handleShowSnackbar={handleShowSnackbar}
              setSnackbarMessage={setSnackbarMessage}
              modaldata={modaldata}
              onClose={() => setShowModal(false)}
            />
          ) : null}
          {showSnackbar && (
            <CustomSnackbar
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
            />
          )}
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Bookings;
