export const WorkShopRmiData = [
  {
    Workshop: "@ Auto Clinic",
    "RMI Number": 1005900,
  },
  {
    Workshop: "3M Car Service & Repair",
    "RMI Number": 4004099,
  },
  {
    Workshop: "4 Wheel Traction Technologies",
    "RMI Number": 1004718,
  },
  {
    Workshop: "458 Performance",
    "RMI Number": 1007867,
  },
  {
    Workshop: "A & K Motors (pty) Ltd-e-car",
    "RMI Number": 1004830,
  },
  {
    Workshop: "A and M Pump Services",
    "RMI Number": 1006469,
  },
  {
    Workshop: "A&V Motor Services",
    "RMI Number": 1002331,
  },
  {
    Workshop: "A1 Car Service",
    "RMI Number": 4005854,
  },
  {
    Workshop: "AA Auto Centre Boksburg",
    "RMI Number": 1,
  },
  {
    Workshop: "AA Auto Centre Kya Sand",
    "RMI Number": 1007413,
  },
  {
    Workshop: "AA Auto Centre Menlyn",
    "RMI Number": 1,
  },
  {
    Workshop: "AA Auto Centre Randburg",
    "RMI Number": 1,
  },
  {
    Workshop: "AA Autosound - Bosch",
    "RMI Number": 1005264,
  },
  {
    Workshop: "Aag Castrol Service",
    "RMI Number": 1008208,
  },
  {
    Workshop: "Aai Auto Bosch Car Service Centre",
    "RMI Number": 1006571,
  },
  {
    Workshop: "AC Auto Services",
    "RMI Number": 1006856,
  },
  {
    Workshop: "AD Automotive",
    "RMI Number": 1006259,
  },
  {
    Workshop: "AE Workshop",
    "RMI Number": 1006049,
  },
  {
    Workshop: "Aero Motor Dynamics",
    "RMI Number": 4004517,
  },
  {
    Workshop: "AG Diagnostics & Service",
    "RMI Number": 1007397,
  },
  {
    Workshop: "Alan Black Pty Ltd _Bosch",
    "RMI Number": 1000053,
  },
  {
    Workshop: "All Bakkie Centre",
    "RMI Number": "\u00a04006102",
  },
  {
    Workshop: "All-O-Matic Transmissions",
    "RMI Number": 1000071,
  },
  {
    Workshop: "Amalgam Autobody Repairs",
    "RMI Number": 1006586,
  },
  {
    Workshop: "Amana Motors CC",
    "RMI Number": 4000032,
  },
  {
    Workshop: "Amo and Gops Car Service",
    "RMI Number": 4006128,
  },
  {
    Workshop: "Amtec Service Centre (pty) Ltd -Bosch",
    "RMI Number": 1000088,
  },
  {
    Workshop: "Andre Bam Auto Clinic",
    "RMI Number": 1003058,
  },
  {
    Workshop: "Angels and Sons Auto Service & Repair Centre",
    "RMI Number": 1006010,
  },
  {
    Workshop: "ASAP Motors",
    "RMI Number": 1006311,
  },
  {
    Workshop: "Aspire Motorsport",
    "RMI Number": 1007499,
  },
  {
    Workshop: "ATE Automotive Repairs and Maintenance",
    "RMI Number": 1007815,
  },
  {
    Workshop: "Augusta Auto",
    "RMI Number": 1005808,
  },
  {
    Workshop: "Aurinia Motors",
    "RMI Number": 4003037,
  },
  {
    Workshop: "Auto Box",
    "RMI Number": 4000099,
  },
  {
    Workshop: "Auto Car Centre",
    "RMI Number": 1007846,
  },
  {
    Workshop: "Auto Care North Riding",
    "RMI Number": 1005244,
  },
  {
    Workshop: "Auto Cool & Sound",
    "RMI Number": 1000142,
  },
  {
    Workshop: "Auto Cure Midrand",
    "RMI Number": 1000143,
  },
  {
    Workshop: "Auto Delight",
    "RMI Number": 1008062,
  },
  {
    Workshop: "Auto Dynamo",
    "RMI Number": 1003881,
  },
  {
    Workshop: "Auto Elegance",
    "RMI Number": 1002944,
  },
  {
    Workshop: "Auto Excellence \u2013 Centurion",
    "RMI Number": 4002841,
  },
  {
    Workshop: "Auto Expedi 8 \u2013 Alberton",
    "RMI Number": 1003887,
  },
  {
    Workshop: "Auto Express Holdings",
    "RMI Number": 1006023,
  },
  {
    Workshop: "Auto GP FMS",
    "RMI Number": 1006800,
  },
  {
    Workshop: "Auto Investments - Centurion",
    "RMI Number": 4003685,
  },
  {
    Workshop: "Auto Kimz",
    "RMI Number": 1007717,
  },
  {
    Workshop: "Auto Magic Mustang",
    "RMI Number": 1004125,
  },
  {
    Workshop: "AUTO MECH",
    "RMI Number": 4003834,
  },
  {
    Workshop: "Auto Paramedics",
    "RMI Number": 1008043,
  },
  {
    Workshop: "Auto Pedigree Service Centre \u2013 Krugersdorp",
    "RMI Number": 1007338,
  },
  {
    Workshop: "Auto Pedigree Service Centre Germiston",
    "RMI Number": 1007339,
  },
  {
    Workshop: "Auto Pedigree Service Centre Head Office",
    "RMI Number": 1007467,
  },
  {
    Workshop: "Auto Pit Stop",
    "RMI Number": 1006579,
  },
  {
    Workshop: "Auto Service World",
    "RMI Number": 4003406,
  },
  {
    Workshop: "Auto Superior - Bosch",
    "RMI Number": 1000174,
  },
  {
    Workshop: "Auto Touch Vehicle Maintenance",
    "RMI Number": 1004675,
  },
  {
    Workshop: "Auto Works Vaal",
    "RMI Number": 1006027,
  },
  {
    Workshop: "Auto Worx",
    "RMI Number": 1002268,
  },
  {
    Workshop: "Autoden Alberton",
    "RMI Number": 1007460,
  },
  {
    Workshop: "Autoden Midrand",
    "RMI Number": 1,
  },
  {
    Workshop: "Auto-Mate Service Centre Greenstone",
    "RMI Number": 1006014,
  },
  {
    Workshop: "Autoserve Solutions",
    "RMI Number": 4004479,
  },
  {
    Workshop: "AutoStar SA",
    "RMI Number": 1007614,
  },
  {
    Workshop: "Autoway Service Centre \u2013 Kya Sand",
    "RMI Number": 1004978,
  },
  {
    Workshop: "Autoway Service Centre \u2013 Ruimsig",
    "RMI Number": 1005596,
  },
  {
    Workshop: "AutoWise Vaal",
    "RMI Number": 1003210,
  },
  {
    Workshop: "AVG Auto Servicing & Repairs",
    "RMI Number": 1003509,
  },
  {
    Workshop: "B & T Benz Chaps",
    "RMI Number": 1006852,
  },
  {
    Workshop: "B H Motors",
    "RMI Number": 1000207,
  },
  {
    Workshop: "B.A.N.G. Auto Repair",
    "RMI Number": 4005862,
  },
  {
    Workshop: "Baleka Motors",
    "RMI Number": 1006823,
  },
  {
    Workshop: "Balo's AutoMech ",
    "RMI Number": 4005922,
  },
  {
    Workshop: "Banda Car Services",
    "RMI Number": 4004345,
  },
  {
    Workshop: "Bar Tech Auto",
    "RMI Number": 1007936,
  },
  {
    Workshop: "Barrage Service Centre",
    "RMI Number": 1008000,
  },
  {
    Workshop: "Bavarian Workshop",
    "RMI Number": 1006367,
  },
  {
    Workshop: "BB Silverton Nissan",
    "RMI Number": 4001746,
  },
  {
    Workshop: "BB Vision Auto Repair",
    "RMI Number": 1007519,
  },
  {
    Workshop: "Bcs Menlyn Auto Centre - Bosch",
    "RMI Number": 4005285,
  },
  {
    Workshop: "Benoni Auto",
    "RMI Number": 1005789,
  },
  {
    Workshop: "Bens Auto Clinic",
    "RMI Number": 1005989,
  },
  {
    Workshop: "Benzecutive Motors",
    "RMI Number": 1006632,
  },
  {
    Workshop: "BestDrive Boksburg",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Bryanston",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Clearwater",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Comaro Crossing",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Constantia Kloof",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive East Rand",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Edenvale",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Fourways",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Greenside",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive JHB Central",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Krugersdorp",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Lenasia",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Northcliff",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Randpark Ridge",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Rosebank",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Silver Lakes",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Silverton Pretoria",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Temba",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Tip Top Tyres & Auto",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Tyre Africa",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Vereeniging",
    "RMI Number": 1005226,
  },
  {
    Workshop: "BestDrive Vereeniging Tyre",
    "RMI Number": 1,
  },
  {
    Workshop: "BestDrive Wilro Corner Tyre and Exhaust",
    "RMI Number": 1,
  },
  {
    Workshop: "Blackheath Autotronic",
    "RMI Number": 1005745,
  },
  {
    Workshop: "Blue Dot Car Service Centre",
    "RMI Number": 4006389,
  },
  {
    Workshop: "BM AUTO Orchards",
    "RMI Number": 4004488,
  },
  {
    Workshop: "B-Ma Services",
    "RMI Number": 4003845,
  },
  {
    Workshop: "BMS Automotive",
    "RMI Number": 1007563,
  },
  {
    Workshop: "Bogale Auto Centre",
    "RMI Number": 4006341,
  },
  {
    Workshop: "Booysens Auto Bosch Car Service",
    "RMI Number": 1008059,
  },
  {
    Workshop: "Bosch Bassonia",
    "RMI Number": 1005024,
  },
  {
    Workshop: "Bosch Car Service",
    "RMI Number": 1007349,
  },
  {
    Workshop: "Bosch Car Service",
    "RMI Number": 4004149,
  },
  {
    Workshop: "Bosch Car Service Montana",
    "RMI Number": 4006144,
  },
  {
    Workshop: "Bosch Car Service Northcliff",
    "RMI Number": 1007317,
  },
  {
    Workshop: "Bosch Car Service Rosslyn",
    "RMI Number": 4006003,
  },
  {
    Workshop: "Bosch Express Mall Of The South",
    "RMI Number": 1008140,
  },
  {
    Workshop: "Bosch Express-rifle Range",
    "RMI Number": 1008175,
  },
  {
    Workshop: "Bosch Express-the Reef",
    "RMI Number": 1008151,
  },
  {
    Workshop: "Bosch Kyalami",
    "RMI Number": 1007757,
  },
  {
    Workshop: "Bosch Rivonia Auto Expert",
    "RMI Number": 1006147,
  },
  {
    Workshop: "Bosch Stone Ridge",
    "RMI Number": 1007470,
  },
  {
    Workshop: "Botha & Deysel Motors",
    "RMI Number": 1008178,
  },
  {
    Workshop: "Braamfontein Service Centre",
    "RMI Number": 1007704,
  },
  {
    Workshop: "Branckens Auto Clinic",
    "RMI Number": 1003128,
  },
  {
    Workshop: "Brit Auto Sport",
    "RMI Number": 1004473,
  },
  {
    Workshop: "Brodies Garage",
    "RMI Number": 1003298,
  },
  {
    Workshop: "Brothers Automotive",
    "RMI Number": 1007408,
  },
  {
    Workshop: "Browns Auto",
    "RMI Number": 1005532,
  },
  {
    Workshop: "Brown's Auto World",
    "RMI Number": 1007906,
  },
  {
    Workshop: "BSF Services & Gearbox Centre",
    "RMI Number": 1007822,
  },
  {
    Workshop: "BT Repairs & Maintenance",
    "RMI Number": 1007664,
  },
  {
    Workshop: "Budler Motorsport",
    "RMI Number": 1004227,
  },
  {
    Workshop: "Canimambo Auto Spares & Repairs",
    "RMI Number": 1005660,
  },
  {
    Workshop: "Car & Truck Auto Electrical-e-car",
    "RMI Number": 1004871,
  },
  {
    Workshop: "Car Care Clinic Alberton",
    "RMI Number": 1007293,
  },
  {
    Workshop: "Car Care Clinic Boksburg",
    "RMI Number": 1007313,
  },
  {
    Workshop: "Car Care Clinic Brooklyn",
    "RMI Number": 4005006,
  },
  {
    Workshop: "Car Care Clinic Centurion",
    "RMI Number": 4004482,
  },
  {
    Workshop: "Car Care Clinic Comaro Crossing",
    "RMI Number": 1007744,
  },
  {
    Workshop: "Car Care Clinic Craighall",
    "RMI Number": 1007963,
  },
  {
    Workshop: "Car Care Clinic Fourways",
    "RMI Number": 1008133,
  },
  {
    Workshop: "Car Care Clinic Little Falls",
    "RMI Number": 1006070,
  },
  {
    Workshop: "Car Care Clinic Lynnwood",
    "RMI Number": 4004465,
  },
  {
    Workshop: "Car Care Clinic Melrose",
    "RMI Number": 1007713,
  },
  {
    Workshop: "Car Care Clinic Midrand",
    "RMI Number": 1006370,
  },
  {
    Workshop: "Car Care Clinic Northcliff",
    "RMI Number": 1007789,
  },
  {
    Workshop: "Car Care Clinic Nothriding",
    "RMI Number": 1005903,
  },
  {
    Workshop: "Car Care Clinic Ormonde",
    "RMI Number": 1007753,
  },
  {
    Workshop: "Car Care Clinic Randfontein",
    "RMI Number": 1007982,
  },
  {
    Workshop: "Car Care Clinic Silverton",
    "RMI Number": 4004970,
  },
  {
    Workshop: "Car Care Clinic Sunninghill",
    "RMI Number": 1008020,
  },
  {
    Workshop: "Car Care Clinic Valley View",
    "RMI Number": 1006156,
  },
  {
    Workshop: "Car Care Clinic Vanderbijlpark",
    "RMI Number": 1007919,
  },
  {
    Workshop: "Car Care Clinic Vereeniging",
    "RMI Number": 1007916,
  },
  {
    Workshop: "Car Care Clinic Wilrogate",
    "RMI Number": 1006527,
  },
  {
    Workshop: "Car Care Clinic Wonderboom",
    "RMI Number": 4006337,
  },
  {
    Workshop: "Car Care Workshop Lynnwood",
    "RMI Number": 4006175,
  },
  {
    Workshop: "Car Care Workshop Waterkloof",
    "RMI Number": 4006161,
  },
  {
    Workshop: "Car Service City  Wonderboom",
    "RMI Number": 4004484,
  },
  {
    Workshop: "Car Service City Alberton",
    "RMI Number": 1006326,
  },
  {
    Workshop: "Car Service City Benoni Lakeside",
    "RMI Number": 1005792,
  },
  {
    Workshop: "Car Service City Benoni Northmead Mall",
    "RMI Number": 1006386,
  },
  {
    Workshop: "Car Service City Boksburg",
    "RMI Number": 1006024,
  },
  {
    Workshop: "Car Service City Braamfontein",
    "RMI Number": 1005986,
  },
  {
    Workshop: "Car Service City Brakpan",
    "RMI Number": 1005541,
  },
  {
    Workshop: "Car Service City Bruma",
    "RMI Number": 1006573,
  },
  {
    Workshop: "Car Service City Cedar",
    "RMI Number": 1004586,
  },
  {
    Workshop: "Car Service City Celtis Ridge",
    "RMI Number": 4003042,
  },
  {
    Workshop: "Car Service City Centurion Gautrain",
    "RMI Number": 1005177,
  },
  {
    Workshop: "Car Service City Centurion Hennopspark",
    "RMI Number": 1005831,
  },
  {
    Workshop: "Car Service City Clearwater",
    "RMI Number": 1005178,
  },
  {
    Workshop: "Car Service City Constantia",
    "RMI Number": 1006349,
  },
  {
    Workshop: "Car Service City Craighall",
    "RMI Number": 1,
  },
  {
    Workshop: "Car Service City Edenvale",
    "RMI Number": 1006159,
  },
  {
    Workshop: "Car Service City Eloff",
    "RMI Number": 1006830,
  },
  {
    Workshop: "Car Service City Germiston",
    "RMI Number": 1006293,
  },
  {
    Workshop: "Car Service City Hatfield",
    "RMI Number": 1006114,
  },
  {
    Workshop: "Car Service City Honeydew",
    "RMI Number": 1005985,
  },
  {
    Workshop: "Car Service City Horizon",
    "RMI Number": 1004669,
  },
  {
    Workshop: "Car Service City Ilovo",
    "RMI Number": 4003035,
  },
  {
    Workshop: "Car Service City Jackal Creek",
    "RMI Number": 1006052,
  },
  {
    Workshop: "Car Service City Jet Park",
    "RMI Number": 1004509,
  },
  {
    Workshop: "Car Service City Kempton Park",
    "RMI Number": 1005628,
  },
  {
    Workshop: "Car Service City Kyalami",
    "RMI Number": 1007240,
  },
  {
    Workshop: "Car Service City Linden",
    "RMI Number": 1005487,
  },
  {
    Workshop: "Car Service City Linmeyer",
    "RMI Number": 1007242,
  },
  {
    Workshop: "Car Service City Lynnwood",
    "RMI Number": 1006654,
  },
  {
    Workshop: "Car Service City Meadowdale",
    "RMI Number": 1006923,
  },
  {
    Workshop: "Car Service City Menlyn",
    "RMI Number": 1005418,
  },
  {
    Workshop: "Car Service City Midrand",
    "RMI Number": 1006136,
  },
  {
    Workshop: "Car Service City Montana",
    "RMI Number": 4002654,
  },
  {
    Workshop: "Car Service City New Road",
    "RMI Number": 1005516,
  },
  {
    Workshop: "Car Service City Northriding",
    "RMI Number": 1005179,
  },
  {
    Workshop: "Car Service City Norwood",
    "RMI Number": 1004877,
  },
  {
    Workshop: "Car Service City Ormonde",
    "RMI Number": 4003353,
  },
  {
    Workshop: "Car Service City PTA CBD Vermeulen Street",
    "RMI Number": 1006689,
  },
  {
    Workshop: "Car Service City Randburg",
    "RMI Number": 1005179,
  },
  {
    Workshop: "Car Service City Rivonia",
    "RMI Number": 1008127,
  },
  {
    Workshop: "Car Service City Sandton",
    "RMI Number": 1007267,
  },
  {
    Workshop: "Car Service City Silverton",
    "RMI Number": 4005388,
  },
  {
    Workshop: "Car Service City Springs",
    "RMI Number": 1006968,
  },
  {
    Workshop: "Car Service City Strijdom Park",
    "RMI Number": 4006174,
  },
  {
    Workshop: "Car Service City Sunninghill",
    "RMI Number": 4005104,
  },
  {
    Workshop: "Car Service City Tambotie",
    "RMI Number": 4005310,
  },
  {
    Workshop: "Car Service City Vanderbijlpark",
    "RMI Number": 1007711,
  },
  {
    Workshop: "Car Service City Wadeville",
    "RMI Number": 1007800,
  },
  {
    Workshop: "Car Service City Westgate",
    "RMI Number": 1005179,
  },
  {
    Workshop: "Car Service City William Nicol",
    "RMI Number": 4005360,
  },
  {
    Workshop: "Car Service City Woodmead",
    "RMI Number": 1007266,
  },
  {
    Workshop: "Car Smart Service Centre",
    "RMI Number": 4005003,
  },
  {
    Workshop: "Carlos W Power",
    "RMI Number": 4000239,
  },
  {
    Workshop: "Carmac Auto",
    "RMI Number": 1007422,
  },
  {
    Workshop: "Carnetics Service Centre",
    "RMI Number": 4002536,
  },
  {
    Workshop: "Carpro Service Centre .",
    "RMI Number": 1003066,
  },
  {
    Workshop: "CARtime Alberton",
    "RMI Number": 1,
  },
  {
    Workshop: "CARtime Benoni",
    "RMI Number": 1007685,
  },
  {
    Workshop: "CARtime Blackheath",
    "RMI Number": 1,
  },
  {
    Workshop: "CARtime Brits",
    "RMI Number": 4006432,
  },
  {
    Workshop: "CARtime Centurion CBD",
    "RMI Number": 4005682,
  },
  {
    Workshop: "CARtime Edenvale",
    "RMI Number": 1,
  },
  {
    Workshop: "CARtime Hatfield",
    "RMI Number": 4005864,
  },
  {
    Workshop: "CARtime Kempton Park",
    "RMI Number": 1,
  },
  {
    Workshop: "CARtime Midrand",
    "RMI Number": 1,
  },
  {
    Workshop: "Cassies Motors",
    "RMI Number": 4000246,
  },
  {
    Workshop: "Castrol Auto Service",
    "RMI Number": 1008121,
  },
  {
    Workshop: "Cats Autohaus",
    "RMI Number": 1004542,
  },
  {
    Workshop: "CCH Mechanical Solutions",
    "RMI Number": 1006359,
  },
  {
    Workshop: "CCVS - Cross Country Vehicle Solutions",
    "RMI Number": 1003392,
  },
  {
    Workshop: "Cencar / Cendiesel - Bosch",
    "RMI Number": 4000251,
  },
  {
    Workshop: "Centurion Automotive",
    "RMI Number": 4005565,
  },
  {
    Workshop: "Centurion Lake Auto",
    "RMI Number": 4002394,
  },
  {
    Workshop: "Citton Cars Bosch",
    "RMI Number": 4002774,
  },
  {
    Workshop: "Clarendon Auto",
    "RMI Number": 1000407,
  },
  {
    Workshop: "Colin's Auto Repairs",
    "RMI Number": 1007444,
  },
  {
    Workshop: "Commander Auto Group",
    "RMI Number": 1007958,
  },
  {
    Workshop: "Corky's Services & Repairs",
    "RMI Number": 1003475,
  },
  {
    Workshop: "CTS Automotive Company ",
    "RMI Number": 4005596,
  },
  {
    Workshop: "CV Master",
    "RMI Number": 4001481,
  },
  {
    Workshop: "D&J Workshop",
    "RMI Number": 4004630,
  },
  {
    Workshop: "D3L Automotive",
    "RMI Number": 1003944,
  },
  {
    Workshop: "Dail Automotive Services",
    "RMI Number": 1008099,
  },
  {
    Workshop: "DAS Auto",
    "RMI Number": 1004039,
  },
  {
    Workshop: "Dastek",
    "RMI Number": 4003653,
  },
  {
    Workshop: "David Binder Motors",
    "RMI Number": 1004427,
  },
  {
    Workshop: "De Wet Automotive",
    "RMI Number": 4005667,
  },
  {
    Workshop: "Deutsch Auto Service Centre",
    "RMI Number": 1006179,
  },
  {
    Workshop: "DHK Automotive Services",
    "RMI Number": 4001631,
  },
  {
    Workshop: "Diagtech Auto",
    "RMI Number": 1006836,
  },
  {
    Workshop: "Diphetogo Motors",
    "RMI Number": 4005221,
  },
  {
    Workshop: "DMN Auto Services",
    "RMI Number": 1008171,
  },
  {
    Workshop: "DO MOTORS",
    "RMI Number": 1005055,
  },
  {
    Workshop: "Double T and K Auto Repair",
    "RMI Number": 4006350,
  },
  {
    Workshop: "DrivePlus Cresta",
    "RMI Number": 1007442,
  },
  {
    Workshop: "DrivePlus Fourways",
    "RMI Number": 1007807,
  },
  {
    Workshop: "Drivetrain Service Centre",
    "RMI Number": 4002672,
  },
  {
    Workshop: "DTEC Motors",
    "RMI Number": 1006227,
  },
  {
    Workshop: "DubTech",
    "RMI Number": 1004578,
  },
  {
    Workshop: "Dynamic National Automotive Trading",
    "RMI Number": 1006283,
  },
  {
    Workshop: "Dzunde Auto Solutions",
    "RMI Number": 4002410,
  },
  {
    Workshop: "E & L Benz Auto",
    "RMI Number": 1007938,
  },
  {
    Workshop: "E&G Motors",
    "RMI Number": 1005249,
  },
  {
    Workshop: "East SA Motor Works",
    "RMI Number": 1004329,
  },
  {
    Workshop: "Eastside Automotive",
    "RMI Number": 1007629,
  },
  {
    Workshop: "e-CAR - A&K Motors",
    "RMI Number": 1,
  },
  {
    Workshop: "e-CAR - Auto Clinic Centurion",
    "RMI Number": 4005054,
  },
  {
    Workshop: "e-CAR - B&R Auto Joburg",
    "RMI Number": 1007754,
  },
  {
    Workshop: "e-CAR - J-Tek Auto",
    "RMI Number": 1,
  },
  {
    Workshop: "e-CAR - MA Jaffer and Sons ",
    "RMI Number": 4005614,
  },
  {
    Workshop: "e-CAR - Menlyn Motor Services",
    "RMI Number": 4000843,
  },
  {
    Workshop: "e-CAR - Technocar Auto Services",
    "RMI Number": 1003198,
  },
  {
    Workshop: "e-CAR Alberton Camaro Auto",
    "RMI Number": 1008091,
  },
  {
    Workshop: "e-CAR Benoni - MPI Benoni",
    "RMI Number": 1,
  },
  {
    Workshop: "e-CAR Boksburg - Tangies Auto",
    "RMI Number": 1,
  },
  {
    Workshop: "ECOTECH Auto Solutions",
    "RMI Number": 4003666,
  },
  {
    Workshop: "Edenvale Auto Services ",
    "RMI Number": 1003041,
  },
  {
    Workshop: "EDL Auto Service Centre",
    "RMI Number": 1007907,
  },
  {
    Workshop: "Efficient Car Service Centre",
    "RMI Number": 1007126,
  },
  {
    Workshop: "Efficient Motor Services",
    "RMI Number": 1002206,
  },
  {
    Workshop: "EL ROI LG Auto Electrical And Mechanical",
    "RMI Number": 1007806,
  },
  {
    Workshop: "Elegant Auto Group \u2013 Vereeniging",
    "RMI Number": 1007178,
  },
  {
    Workshop: "Elite Automotive Solutions",
    "RMI Number": 1007558,
  },
  {
    Workshop: "Eurospec Auto",
    "RMI Number": 1006149,
  },
  {
    Workshop: "Extreme Performance",
    "RMI Number": 1007939,
  },
  {
    Workshop: "Fairland Auto",
    "RMI Number": 1007475,
  },
  {
    Workshop: "Faria's Auto Services",
    "RMI Number": 1007901,
  },
  {
    Workshop: "Finla Mechanic & Auto Electrical - Bosch",
    "RMI Number": 4002847,
  },
  {
    Workshop: "FIT-IT Service and Repair Centre",
    "RMI Number": 4002868,
  },
  {
    Workshop: "Fixon Auto",
    "RMI Number": 1006819,
  },
  {
    Workshop: "Flora Auto Clinic",
    "RMI Number": 1007252,
  },
  {
    Workshop: "Fmc- Bosch Car Service",
    "RMI Number": 1007758,
  },
  {
    Workshop: "Frik Nel Motors - ACD",
    "RMI Number": 4003352,
  },
  {
    Workshop: "Fst Auto Service Cc - Bosch",
    "RMI Number": 1005185,
  },
  {
    Workshop: "G & C Performance & Service Centre ",
    "RMI Number": 1005703,
  },
  {
    Workshop: "G.F.M. Motors",
    "RMI Number": 1004894,
  },
  {
    Workshop: "Gabe Mobile Auto",
    "RMI Number": 1007401,
  },
  {
    Workshop: "Gail Auto Services",
    "RMI Number": 4003696,
  },
  {
    Workshop: "Garsfontein Motors Service Workshop",
    "RMI Number": 4002947,
  },
  {
    Workshop: "GBS Autotroniks",
    "RMI Number": 1007538,
  },
  {
    Workshop: "Gem Auto Service & Repairs ",
    "RMI Number": 1007959,
  },
  {
    Workshop: "GENX Auto Specialists",
    "RMI Number": 4005715,
  },
  {
    Workshop: "GKT Motors",
    "RMI Number": 4003764,
  },
  {
    Workshop: "Glenridge Motors",
    "RMI Number": 4002337,
  },
  {
    Workshop: "GMP Auto Services",
    "RMI Number": 1003411,
  },
  {
    Workshop: "Gorilla Pit Garage",
    "RMI Number": 1007203,
  },
  {
    Workshop: "GP Motor Works",
    "RMI Number": 1006681,
  },
  {
    Workshop: "Grease Monkey Motor Garage",
    "RMI Number": 4004110,
  },
  {
    Workshop: "Group 3 Automotive",
    "RMI Number": 4005327,
  },
  {
    Workshop: "GRS Motorsport",
    "RMI Number": 1003098,
  },
  {
    Workshop: "GT One",
    "RMI Number": 1005638,
  },
  {
    Workshop: "Hands On Car Services And Repairs",
    "RMI Number": 4005696,
  },
  {
    Workshop: "Harry's Auto Services",
    "RMI Number": 4000531,
  },
  {
    Workshop: "Hatfield Car Caf\u00e8",
    "RMI Number": 4005311,
  },
  {
    Workshop: "Hattingh Motors",
    "RMI Number": 4000595,
  },
  {
    Workshop: "Hatwagen Motors",
    "RMI Number": 4002243,
  },
  {
    Workshop: "Hauser Auto Services",
    "RMI Number": 1002504,
  },
  {
    Workshop: "Haval Hatfield",
    "RMI Number": 4006208,
  },
  {
    Workshop: "Henri's Tune-Up Centre",
    "RMI Number": 1004155,
  },
  {
    Workshop: "Highveld Autoworks",
    "RMI Number": 4006185,
  },
  {
    Workshop: "Hillfox Autotronics - Bosch",
    "RMI Number": 1002475,
  },
  {
    Workshop: "HM Auto",
    "RMI Number": 1006843,
  },
  {
    Workshop: "HVN Auto Repair and Diagnostics Centre",
    "RMI Number": 4005266,
  },
  {
    Workshop: "ICR Auto",
    "RMI Number": 1007304,
  },
  {
    Workshop: "Ilan Motors",
    "RMI Number": 1006323,
  },
  {
    Workshop: "Influx Auto",
    "RMI Number": 4005876,
  },
  {
    Workshop: "Ingrid Autoz",
    "RMI Number": 1008067,
  },
  {
    Workshop: "Insyte Auto",
    "RMI Number": 1007794,
  },
  {
    Workshop: "Interfix 1",
    "RMI Number": 4004350,
  },
  {
    Workshop: "InterGear Auto Service Centre",
    "RMI Number": 1006295,
  },
  {
    Workshop: "Isuzu World",
    "RMI Number": 1005132,
  },
  {
    Workshop: "J&M Auto Repairs",
    "RMI Number": 4005105,
  },
  {
    Workshop: "J&R Auto Services ",
    "RMI Number": 1006668,
  },
  {
    Workshop: "J.A.G Auto Tech",
    "RMI Number": 1000962,
  },
  {
    Workshop: "JDS Auto",
    "RMI Number": 1005568,
  },
  {
    Workshop: "JL Auto Performance & Maintenance",
    "RMI Number": 1007596,
  },
  {
    Workshop: "JLF Automotive",
    "RMI Number": 4006210,
  },
  {
    Workshop: "JMM Auto Repairs",
    "RMI Number": 1007983,
  },
  {
    Workshop: "Joburg Auto Tech",
    "RMI Number": 1003946,
  },
  {
    Workshop: "Jorge's Auto Clinic",
    "RMI Number": 4000883,
  },
  {
    Workshop: "JP Motor Services",
    "RMI Number": 1006371,
  },
  {
    Workshop: "J-TECH Service & Repair Centre",
    "RMI Number": 4002415,
  },
  {
    Workshop: "K&B Services",
    "RMI Number": 4003336,
  },
  {
    Workshop: "Kani Motor Repairs & Spares",
    "RMI Number": 1003825,
  },
  {
    Workshop: "Keesh Edition Auto Group",
    "RMI Number": 1007451,
  },
  {
    Workshop: "Kenny General Repairs",
    "RMI Number": 4006228,
  },
  {
    Workshop: "Ken's Auto Clinic",
    "RMI Number": 1007204,
  },
  {
    Workshop: "Kilokor Motors & Rentals",
    "RMI Number": 1004535,
  },
  {
    Workshop: "KoeniC Wheel Warehouse",
    "RMI Number": 1005627,
  },
  {
    Workshop: "KOOPS Automotive Repair Center",
    "RMI Number": 4000675,
  },
  {
    Workshop: "KSM Autoshop",
    "RMI Number": 4003354,
  },
  {
    Workshop: "Kwik Fix Auto",
    "RMI Number": 1005277,
  },
  {
    Workshop: "Kyalami Grand Prix Auto",
    "RMI Number": 1007740,
  },
  {
    Workshop: "L2G Workshop & Panelbeating",
    "RMI Number": 1008166,
  },
  {
    Workshop: "Landy Clinic Auto",
    "RMI Number": 1008034,
  },
  {
    Workshop: "Landy Tech",
    "RMI Number": 1003864,
  },
  {
    Workshop: "LandyZone SA",
    "RMI Number": 1007483,
  },
  {
    Workshop: "Legends Auto-Tech",
    "RMI Number": 4006068,
  },
  {
    Workshop: "Lenasia Auto Care - Bosch",
    "RMI Number": 1004645,
  },
  {
    Workshop: "Leons Service Centre - ACD",
    "RMI Number": 1003421,
  },
  {
    Workshop: "Little Falls Auto - Bosch",
    "RMI Number": 1006887,
  },
  {
    Workshop: "Lochson Autobody",
    "RMI Number": 4005560,
  },
  {
    Workshop: "Logical Auto Solutions",
    "RMI Number": 1007722,
  },
  {
    Workshop: "LR Auto Workshop & Service Centre",
    "RMI Number": 4005466,
  },
  {
    Workshop: "LRXtreme",
    "RMI Number": 4003730,
  },
  {
    Workshop: "LTU Dyno Service Centre",
    "RMI Number": 1005279,
  },
  {
    Workshop: "Lumo Motorworld",
    "RMI Number": 1007179,
  },
  {
    Workshop: "Mabopane Auto Centre",
    "RMI Number": 4005894,
  },
  {
    Workshop: "Manie's Auto Services",
    "RMI Number": 1007107,
  },
  {
    Workshop: "Maqweqwe Automotive",
    "RMI Number": 4006151,
  },
  {
    Workshop: "Marks Auto",
    "RMI Number": 1006186,
  },
  {
    Workshop: "Martin Le Roux Motors",
    "RMI Number": 1001174,
  },
  {
    Workshop: "Martins Motor Sport",
    "RMI Number": 1008138,
  },
  {
    Workshop: "Masase Automotive",
    "RMI Number": 4004943,
  },
  {
    Workshop: "Mashobadieta Business Enterprise \u2013 Baloyi Motor Repairs",
    "RMI Number": 1004506,
  },
  {
    Workshop: "Mavi Auto Group",
    "RMI Number": 4004535,
  },
  {
    Workshop: "MBS Motor Spares",
    "RMI Number": 1005399,
  },
  {
    Workshop: "MCJ Service Centre",
    "RMI Number": 1004480,
  },
  {
    Workshop: "Mechanical Masters",
    "RMI Number": 4003580,
  },
  {
    Workshop: "Mechspec Auto Bosch",
    "RMI Number": 1008123,
  },
  {
    Workshop: "MechTech Solutions ",
    "RMI Number": 4003628,
  },
  {
    Workshop: "Mectech Motors",
    "RMI Number": 1006825,
  },
  {
    Workshop: "MercFormance",
    "RMI Number": 1007621,
  },
  {
    Workshop: "Merc-Tec Services,Diagnostics & Repairs ",
    "RMI Number": 1007652,
  },
  {
    Workshop: "Mesede Motors",
    "RMI Number": 1005526,
  },
  {
    Workshop: "MF Repair and Tune-up Centre",
    "RMI Number": 1005597,
  },
  {
    Workshop: "Midas \u2013 Wynberg",
    "RMI Number": 1004190,
  },
  {
    Workshop: "Midvaal Service Centre",
    "RMI Number": 1006883,
  },
  {
    Workshop: "MJ Motor Repairs",
    "RMI Number": 1001128,
  },
  {
    Workshop: "Mk Supertech",
    "RMI Number": 1006844,
  },
  {
    Workshop: "Mm Automotive And Projects",
    "RMI Number": 1007719,
  },
  {
    Workshop: "Mm Service Centre",
    "RMI Number": 1008046,
  },
  {
    Workshop: "Mmr Bluewagon Holdings",
    "RMI Number": 4004210,
  },
  {
    Workshop: "Mobilis Auto Lab T/a Bheka Umbuso Holdin",
    "RMI Number": 1007302,
  },
  {
    Workshop: "Mococo T & P",
    "RMI Number": 1008197,
  },
  {
    Workshop: "Mohlala Motors",
    "RMI Number": 1007654,
  },
  {
    Workshop: "Mokgaga Auto Repairs",
    "RMI Number": 1008102,
  },
  {
    Workshop: "Momentum Auto",
    "RMI Number": 1006996,
  },
  {
    Workshop: "Mono Technique",
    "RMI Number": 1006240,
  },
  {
    Workshop: "Montana Park Service Centre",
    "RMI Number": 4005959,
  },
  {
    Workshop: "Montedi Motor Clinic",
    "RMI Number": 1006241,
  },
  {
    Workshop: "Moreleta Service Centre",
    "RMI Number": 4005398,
  },
  {
    Workshop: "Moroder Auto Care",
    "RMI Number": 1007859,
  },
  {
    Workshop: "Morwa Moleke Trading Pty Ltd",
    "RMI Number": 1007776,
  },
  {
    Workshop: "Motolek \u2013 Lenasia",
    "RMI Number": 1005691,
  },
  {
    Workshop: "Motolek \u2013 Silverton",
    "RMI Number": 4004966,
  },
  {
    Workshop: "Motomark Service Centre",
    "RMI Number": 4004782,
  },
  {
    Workshop: "Motor Accident Group",
    "RMI Number": 1004199,
  },
  {
    Workshop: "Motor Spec Fleetline",
    "RMI Number": 1007495,
  },
  {
    Workshop: "Motor Surgery",
    "RMI Number": 1006570,
  },
  {
    Workshop: "Motor Wize Auto Repairs",
    "RMI Number": 4006277,
  },
  {
    Workshop: "Motoreasy",
    "RMI Number": 4004443,
  },
  {
    Workshop: "Motorpro",
    "RMI Number": 1006575,
  },
  {
    Workshop: "Motorsport Inc",
    "RMI Number": 1003019,
  },
  {
    Workshop: "Motoserv",
    "RMI Number": 1003917,
  },
  {
    Workshop: "Moto-technik",
    "RMI Number": 1004154,
  },
  {
    Workshop: "Motsimani Pty Ltd",
    "RMI Number": 4003578,
  },
  {
    Workshop: "Motswa Pru Nl Trading And Projects",
    "RMI Number": 4004720,
  },
  {
    Workshop: "Mp Tuned",
    "RMI Number": 1005631,
  },
  {
    Workshop: "Mpp Auto Mechanical",
    "RMI Number": 4003760,
  },
  {
    Workshop: "Mr Mechanic",
    "RMI Number": 1006669,
  },
  {
    Workshop: "Mr Propshaft",
    "RMI Number": 1001280,
  },
  {
    Workshop: "Mte Truck & Trailer Service Centre",
    "RMI Number": 1006824,
  },
  {
    Workshop: "Mtecs Auto Centre",
    "RMI Number": 1007443,
  },
  {
    Workshop: "Mthunzi Auto And Suspension",
    "RMI Number": 4005096,
  },
  {
    Workshop: "Mtv Auto Group",
    "RMI Number": 1007955,
  },
  {
    Workshop: "Mycrest Trading",
    "RMI Number": 1007273,
  },
  {
    Workshop: "Nashed Tuned",
    "RMI Number": 1007817,
  },
  {
    Workshop: "National Automotive Solutions",
    "RMI Number": 4005119,
  },
  {
    Workshop: "Naude\u2019s Car And Truck",
    "RMI Number": 1007951,
  },
  {
    Workshop: "Nducks Maintenance And Logistics",
    "RMI Number": 1006039,
  },
  {
    Workshop: "Nelo Motor Services",
    "RMI Number": 1005620,
  },
  {
    Workshop: "New Order Investment 46 Pty Ltd",
    "RMI Number": 4003316,
  },
  {
    Workshop: "Newshelf 1369 Proprietary Limited",
    "RMI Number": 1006265,
  },
  {
    Workshop: "Nfs Tuning",
    "RMI Number": 1007912,
  },
  {
    Workshop: "Ngb Auto",
    "RMI Number": 1007264,
  },
  {
    Workshop: "Ngwazi Transport And Logistics",
    "RMI Number": 4006009,
  },
  {
    Workshop: "Nh Tech Motors Cc",
    "RMI Number": 1005232,
  },
  {
    Workshop: "Nico Britz Racing & Fleet Maintanan",
    "RMI Number": 1005567,
  },
  {
    Workshop: "Nige Auto",
    "RMI Number": 1004656,
  },
  {
    Workshop: "Nigel Motor Services",
    "RMI Number": 1007208,
  },
  {
    Workshop: "Nix Auto Trix",
    "RMI Number": 1007353,
  },
  {
    Workshop: "Njy Motors",
    "RMI Number": 1008053,
  },
  {
    Workshop: "Nkn Auto Clinic",
    "RMI Number": 1008209,
  },
  {
    Workshop: "Nkunas Auto Services",
    "RMI Number": 1008202,
  },
  {
    Workshop: "No Problem Auto",
    "RMI Number": 4003545,
  },
  {
    Workshop: "Northway Electrauto",
    "RMI Number": 1004315,
  },
  {
    Workshop: "Nortons Car Clinic Cc",
    "RMI Number": 1002582,
  },
  {
    Workshop: "Nova Auto Centre",
    "RMI Number": 4004502,
  },
  {
    Workshop: "Npc Mechanical Services",
    "RMI Number": 1007914,
  },
  {
    Workshop: "O T N Car Care",
    "RMI Number": 4004474,
  },
  {
    Workshop: "O2 Motor Company",
    "RMI Number": 1004027,
  },
  {
    Workshop: "Oe Airconditioning Spares Cc",
    "RMI Number": 1001340,
  },
  {
    Workshop: "Olwagen Motors",
    "RMI Number": 4003438,
  },
  {
    Workshop: "Omonama Auto Service",
    "RMI Number": 4003646,
  },
  {
    Workshop: "Onfleet Mechanical",
    "RMI Number": 1008024,
  },
  {
    Workshop: "Ott Technologies Pty Ltd",
    "RMI Number": 4000951,
  },
  {
    Workshop: "Overland Worx.",
    "RMI Number": 1004428,
  },
  {
    Workshop: "P & D Auto Services",
    "RMI Number": 1003287,
  },
  {
    Workshop: "P And H Merc Specialist",
    "RMI Number": 1006670,
  },
  {
    Workshop: "Pangolin Motors",
    "RMI Number": 4004850,
  },
  {
    Workshop: "Papa Piet Trading And Projects Pty (ltd)",
    "RMI Number": 4005084,
  },
  {
    Workshop: "Paringa Auto Centre For Vehicle Repairs",
    "RMI Number": 4003679,
  },
  {
    Workshop: "Parker Auto Motors",
    "RMI Number": 1007431,
  },
  {
    Workshop: "Parkhurst Auto",
    "RMI Number": 1006647,
  },
  {
    Workshop: "Patrix Service Centre (pty) Ltd",
    "RMI Number": 1005706,
  },
  {
    Workshop: "Pauls Auto Services",
    "RMI Number": 1006687,
  },
  {
    Workshop: "Pauls Mini Workshop",
    "RMI Number": 4003286,
  },
  {
    Workshop: "Pauls Workshop",
    "RMI Number": 1002919,
  },
  {
    Workshop: "Peet Jansen Service Centre",
    "RMI Number": 4000988,
  },
  {
    Workshop: "Perfect Auto Worx Haus",
    "RMI Number": 4006045,
  },
  {
    Workshop: "Perfect Reliable Perfomance",
    "RMI Number": 1005817,
  },
  {
    Workshop: "Performax Tuning",
    "RMI Number": 1004843,
  },
  {
    Workshop: "Performer 2000",
    "RMI Number": 1004895,
  },
  {
    Workshop: "Perkins Automotive",
    "RMI Number": 4005462,
  },
  {
    Workshop: "Peters Auto Care",
    "RMI Number": 1002476,
  },
  {
    Workshop: "Pfani And The Mechanics Workshop",
    "RMI Number": 1005604,
  },
  {
    Workshop: "Phala Brakes & Clutch",
    "RMI Number": 4005299,
  },
  {
    Workshop: "Pincar Auto Services",
    "RMI Number": 1007892,
  },
  {
    Workshop: "Piston And Polish Centre",
    "RMI Number": 1007389,
  },
  {
    Workshop: "Pit Motors",
    "RMI Number": 1001405,
  },
  {
    Workshop: "Polo Auto Air Cc",
    "RMI Number": 1002994,
  },
  {
    Workshop: "Powermatics",
    "RMI Number": 1004906,
  },
  {
    Workshop: "Precision Auto Tuning",
    "RMI Number": 1005330,
  },
  {
    Workshop: "Pretoria East Service City Cc",
    "RMI Number": 4003543,
  },
  {
    Workshop: "Pretorius And Sons Pty Ltd",
    "RMI Number": 4005896,
  },
  {
    Workshop: "Primo Executive Cars",
    "RMI Number": 1006442,
  },
  {
    Workshop: "Pro Auto Repairs",
    "RMI Number": 1006934,
  },
  {
    Workshop: "Pro Merc Auto",
    "RMI Number": 4005354,
  },
  {
    Workshop: "Pro Nordic Motors Cc",
    "RMI Number": 1004680,
  },
  {
    Workshop: "Pro-master Mechanical",
    "RMI Number": 4004331,
  },
  {
    Workshop: "Propshaft City",
    "RMI Number": 1001448,
  },
  {
    Workshop: "Propshaft People",
    "RMI Number": 4002825,
  },
  {
    Workshop: "Protec Auto Benoni Bosch",
    "RMI Number": 1005994,
  },
  {
    Workshop: "Protec Auto Kempton Park",
    "RMI Number": 1003083,
  },
  {
    Workshop: "Psycho Auto Electrical",
    "RMI Number": 1007195,
  },
  {
    Workshop: "Pubs Holdings",
    "RMI Number": 1008199,
  },
  {
    Workshop: "Puff Auto",
    "RMI Number": 1007508,
  },
  {
    Workshop: "Puzzle Master Motors",
    "RMI Number": 1006610,
  },
  {
    Workshop: "Quick Lane Silverton",
    "RMI Number": 4004372,
  },
  {
    Workshop: "Quicklane Blue Hills",
    "RMI Number": 1007562,
  },
  {
    Workshop: "R & A Tune Up Centre",
    "RMI Number": 1004933,
  },
  {
    Workshop: "R & D Trekker & Motordiens",
    "RMI Number": 1007723,
  },
  {
    Workshop: "R2 Workshops (pty) Ltd",
    "RMI Number": 1006148,
  },
  {
    Workshop: "R2r Auto Electrical Service & Repai",
    "RMI Number": 4004790,
  },
  {
    Workshop: "R55 Service Centre",
    "RMI Number": 4006227,
  },
  {
    Workshop: "R59 Auto Service",
    "RMI Number": 1007448,
  },
  {
    Workshop: "Randburg Auto Repairs",
    "RMI Number": 1006476,
  },
  {
    Workshop: "Raza Auto",
    "RMI Number": 1006745,
  },
  {
    Workshop: "Rc Auto Repairs",
    "RMI Number": 1003435,
  },
  {
    Workshop: "Rdg Commercial",
    "RMI Number": 1006655,
  },
  {
    Workshop: "Reign Auto Center",
    "RMI Number": 1007500,
  },
  {
    Workshop: "Retrohaus",
    "RMI Number": 4005645,
  },
  {
    Workshop: "Rex Diff And Gearbox Pretoria",
    "RMI Number": 4004775,
  },
  {
    Workshop: "Rfs Auto",
    "RMI Number": 4004219,
  },
  {
    Workshop: "Rhr Service Centre",
    "RMI Number": 1000237,
  },
  {
    Workshop: "Richard Service Centre",
    "RMI Number": 1006264,
  },
  {
    Workshop: "Ridgewood Auto Service",
    "RMI Number": 4002713,
  },
  {
    Workshop: "Rileys Service And Repair Centre",
    "RMI Number": 1006606,
  },
  {
    Workshop: "Rilo Auto Electrical Cc",
    "RMI Number": 1003196,
  },
  {
    Workshop: "Riverside Auto City-rac",
    "RMI Number": 1007877,
  },
  {
    Workshop: "Rjb Mechanical Repair",
    "RMI Number": 4004520,
  },
  {
    Workshop: "Rjs Auto Performance",
    "RMI Number": 1007261,
  },
  {
    Workshop: "Rl Service Centre",
    "RMI Number": 4004002,
  },
  {
    Workshop: "Rm Auto Care",
    "RMI Number": 1008155,
  },
  {
    Workshop: "Roadranger Transport",
    "RMI Number": 1007280,
  },
  {
    Workshop: "Rodac Diesel Injection Pty",
    "RMI Number": 1001529,
  },
  {
    Workshop: "Roelf Kruger Auto Repairs Motorherstelwe",
    "RMI Number": 4001546,
  },
  {
    Workshop: "Roelfs Auto Electrical",
    "RMI Number": 4004401,
  },
  {
    Workshop: "Rogens Auto Centre Pty Ltd",
    "RMI Number": 4001104,
  },
  {
    Workshop: "Rolans Fitment & Motor Repair",
    "RMI Number": 1004477,
  },
  {
    Workshop: "Ronnies Tyres",
    "RMI Number": 1004564,
  },
  {
    Workshop: "Ronnies Tyres \u2013 Wilropark",
    "RMI Number": 1006086,
  },
  {
    Workshop: "Ross Duncan Auto Services Cc",
    "RMI Number": 1005835,
  },
  {
    Workshop: "Route 24 Service Centre",
    "RMI Number": 1007210,
  },
  {
    Workshop: "Ryans Auto Worx",
    "RMI Number": 4005468,
  },
  {
    Workshop: "S A Reserve Bank Head Office",
    "RMI Number": 4004272,
  },
  {
    Workshop: "Sabre Motors",
    "RMI Number": 1007034,
  },
  {
    Workshop: "Sac Alberton",
    "RMI Number": 1006592,
  },
  {
    Workshop: "Sac Diesel Kyalami Cc",
    "RMI Number": 4003380,
  },
  {
    Workshop: "Sac East Rand",
    "RMI Number": 1006705,
  },
  {
    Workshop: "Safari Projek Pty Ltd",
    "RMI Number": 4006404,
  },
  {
    Workshop: "Safe Time Auto Electrical",
    "RMI Number": 1004432,
  },
  {
    Workshop: "Safety Brake & Clutch \u2013 Edenv",
    "RMI Number": 1003885,
  },
  {
    Workshop: "Sagarage",
    "RMI Number": 1006826,
  },
  {
    Workshop: "Sams Auto Services",
    "RMI Number": 1004723,
  },
  {
    Workshop: "Savoy Car Aircon & Sound Centre",
    "RMI Number": 1006381,
  },
  {
    Workshop: "Scooby Worx",
    "RMI Number": 1005747,
  },
  {
    Workshop: "Scuderia South Africa (pty) Ltd",
    "RMI Number": 1004392,
  },
  {
    Workshop: "Scw Auto Electrical",
    "RMI Number": 4006066,
  },
  {
    Workshop: "Sd Group",
    "RMI Number": 1007013,
  },
  {
    Workshop: "Sebenza Rtc",
    "RMI Number": 1002457,
  },
  {
    Workshop: "Seelo Performance",
    "RMI Number": 1008162,
  },
  {
    Workshop: "Sehlabaka Engineering",
    "RMI Number": 1007761,
  },
  {
    Workshop: "Select Auto Group",
    "RMI Number": 4005456,
  },
  {
    Workshop: "Service 2 Service",
    "RMI Number": 1008125,
  },
  {
    Workshop: "Shami Business Solutions",
    "RMI Number": 1007734,
  },
  {
    Workshop: "Siboneni Motor Center",
    "RMI Number": 1007898,
  },
  {
    Workshop: "Sir Autohaus",
    "RMI Number": 1005350,
  },
  {
    Workshop: "Sithembe Transportation & Services",
    "RMI Number": 4005958,
  },
  {
    Workshop: "Siyadumisa Fleet Maintenance Cc",
    "RMI Number": 1004739,
  },
  {
    Workshop: "Siyanjalo Auto",
    "RMI Number": 1008089,
  },
  {
    Workshop: "Siza James Auto Clinic",
    "RMI Number": 1005367,
  },
  {
    Workshop: "Smash Bakkie Service Spares Propshaft Ce",
    "RMI Number": 4004473,
  },
  {
    Workshop: "Smw Swartz Motor Worx",
    "RMI Number": 1005746,
  },
  {
    Workshop: "Solihull 4 Wd",
    "RMI Number": 1005026,
  },
  {
    Workshop: "Sondela Auto",
    "RMI Number": 1005893,
  },
  {
    Workshop: "Sp Carbs Edms Bpk",
    "RMI Number": 1001561,
  },
  {
    Workshop: "Speed Auto Cc",
    "RMI Number": 1004442,
  },
  {
    Workshop: "Sportline Auto",
    "RMI Number": 1002417,
  },
  {
    Workshop: "Stallone Auto Electrical",
    "RMI Number": 1001685,
  },
  {
    Workshop: "Starfix Auto Centre",
    "RMI Number": 1004538,
  },
  {
    Workshop: "Stargen Auto Electrical Cc",
    "RMI Number": 4001205,
  },
  {
    Workshop: "Steering & Mechanical Wizard",
    "RMI Number": 4005037,
  },
  {
    Workshop: "Steyntjies Field Servicing",
    "RMI Number": 1007297,
  },
  {
    Workshop: "Stp Mechanics",
    "RMI Number": 4006026,
  },
  {
    Workshop: "Stuart Coal",
    "RMI Number": 1008163,
  },
  {
    Workshop: "Summit Brake & Steering",
    "RMI Number": 1001716,
  },
  {
    Workshop: "Supafix Auto",
    "RMI Number": 1006925,
  },
  {
    Workshop: "Super Groove Fitment And Repair Centre",
    "RMI Number": 1008225,
  },
  {
    Workshop: "Super Tune",
    "RMI Number": 1002322,
  },
  {
    Workshop: "Superior Car Clinic",
    "RMI Number": 1004946,
  },
  {
    Workshop: "Supertech Auto",
    "RMI Number": 4004227,
  },
  {
    Workshop: "Supertek Auto Works",
    "RMI Number": 1005733,
  },
  {
    Workshop: "Supreme Auto Works",
    "RMI Number": 1006465,
  },
  {
    Workshop: "Svds Motors",
    "RMI Number": 4003821,
  },
  {
    Workshop: "Swedish Auto Cc",
    "RMI Number": 1003550,
  },
  {
    Workshop: "Sync Automotive",
    "RMI Number": 1007118,
  },
  {
    Workshop: "T/a Bb Menlyn Ford",
    "RMI Number": 4000842,
  },
  {
    Workshop: "Talladega Auto Clinic",
    "RMI Number": 4003001,
  },
  {
    Workshop: "Tau Premium Group",
    "RMI Number": 1008036,
  },
  {
    Workshop: "Tc Auto And Fleet Maintenance",
    "RMI Number": 1007895,
  },
  {
    Workshop: "Td & Hb Auto Repairs",
    "RMI Number": 4004277,
  },
  {
    Workshop: "Techno Spec",
    "RMI Number": 1004081,
  },
  {
    Workshop: "Tele Auto Service Centre C",
    "RMI Number": 1007530,
  },
  {
    Workshop: "Temba Auto Clinic",
    "RMI Number": 1005033,
  },
  {
    Workshop: "Tens Pretoria",
    "RMI Number": 4005389,
  },
  {
    Workshop: "Thato Truck Clinic",
    "RMI Number": 1006797,
  },
  {
    Workshop: "The Auto Garage",
    "RMI Number": 1007752,
  },
  {
    Workshop: "The Beamer Boyz Motor Mechanics",
    "RMI Number": 1007862,
  },
  {
    Workshop: "The Big Friendly Giant",
    "RMI Number": 4005050,
  },
  {
    Workshop: "The Big Three Distributors Cc",
    "RMI Number": 1003434,
  },
  {
    Workshop: "The Bm Guy",
    "RMI Number": 1007610,
  },
  {
    Workshop: "The Garage",
    "RMI Number": 1006866,
  },
  {
    Workshop: "The Garage Dunkeld",
    "RMI Number": 1007150,
  },
  {
    Workshop: "The Garage Edenvale",
    "RMI Number": 1008111,
  },
  {
    Workshop: "The Journeymann Motor \u2013 Benoni",
    "RMI Number": 1003334,
  },
  {
    Workshop: "The Kings Auto",
    "RMI Number": 1007414,
  },
  {
    Workshop: "The Partners Workshop Cc",
    "RMI Number": 1003356,
  },
  {
    Workshop: "The Workshop \u2013 Northcliff",
    "RMI Number": 1004221,
  },
  {
    Workshop: "Theonick Engineering",
    "RMI Number": 1007432,
  },
  {
    Workshop: "Thes Service Centre",
    "RMI Number": 1005396,
  },
  {
    Workshop: "Titan Automotive",
    "RMI Number": 1007331,
  },
  {
    Workshop: "Tlalas Auto Repair",
    "RMI Number": 1006890,
  },
  {
    Workshop: "Tman Workshop Mechanics",
    "RMI Number": 4006275,
  },
  {
    Workshop: "Tmk Auto",
    "RMI Number": 4005601,
  },
  {
    Workshop: "Tmms",
    "RMI Number": 1003999,
  },
  {
    Workshop: "Top Care Auto Clinic \u2013 Midrand",
    "RMI Number": 1005029,
  },
  {
    Workshop: "Top Speed Motors",
    "RMI Number": 1006297,
  },
  {
    Workshop: "Top Torque Auto Clinic",
    "RMI Number": 1005633,
  },
  {
    Workshop: "Toropa Trading And Enterprise",
    "RMI Number": 1006291,
  },
  {
    Workshop: "Total Dyna Tune Up",
    "RMI Number": 1003440,
  },
  {
    Workshop: "Transport Motor Repairs",
    "RMI Number": 4004971,
  },
  {
    Workshop: "Transpower Motors",
    "RMI Number": 1006067,
  },
  {
    Workshop: "Traxx Auto Service",
    "RMI Number": 4005888,
  },
  {
    Workshop: "Trip 3 Automotive",
    "RMI Number": 1007925,
  },
  {
    Workshop: "Triton Fleet Management",
    "RMI Number": 1005932,
  },
  {
    Workshop: "Truckpart",
    "RMI Number": 4002322,
  },
  {
    Workshop: "Tshirivha Auto Mechanic",
    "RMI Number": 1007778,
  },
  {
    Workshop: "Tshwane Auto Services",
    "RMI Number": 4002321,
  },
  {
    Workshop: "Ttp Auto Solution",
    "RMI Number": 4006286,
  },
  {
    Workshop: "Tune Tech",
    "RMI Number": 1001858,
  },
  {
    Workshop: "Turbo & Service Centre",
    "RMI Number": 1007798,
  },
  {
    Workshop: "Turboworks",
    "RMI Number": 4003074,
  },
  {
    Workshop: "Tvr Auto Electrical Services Cc",
    "RMI Number": 1004340,
  },
  {
    Workshop: "Twin Brother Enterprise & Propshaft",
    "RMI Number": 4003826,
  },
  {
    Workshop: "Ukulungiswa Group",
    "RMI Number": 1008027,
  },
  {
    Workshop: "Ultimate Auto",
    "RMI Number": 1006759,
  },
  {
    Workshop: "Ultimate Performance",
    "RMI Number": 1005661,
  },
  {
    Workshop: "Ultimate Performance Centurion",
    "RMI Number": 4006311,
  },
  {
    Workshop: "Uminathi Auto Services",
    "RMI Number": 1007312,
  },
  {
    Workshop: "Unique Automotive Clinic Pty Ltd",
    "RMI Number": 4004510,
  },
  {
    Workshop: "Unique Motorsport Pty Ltd",
    "RMI Number": 4004286,
  },
  {
    Workshop: "Uns Trading Projects",
    "RMI Number": 1007649,
  },
  {
    Workshop: "Urbn Auto Pretoria",
    "RMI Number": 4006166,
  },
  {
    Workshop: "Uws Projects",
    "RMI Number": 1006793,
  },
  {
    Workshop: "V & S Services",
    "RMI Number": 4004934,
  },
  {
    Workshop: "V And N Workshop",
    "RMI Number": 4003893,
  },
  {
    Workshop: "V2k Service & Repair Centre",
    "RMI Number": 1006357,
  },
  {
    Workshop: "Va Auto Repairs",
    "RMI Number": 1003854,
  },
  {
    Workshop: "Vag Automotive Centre Centurion",
    "RMI Number": 4003931,
  },
  {
    Workshop: "Vag Motor Sport",
    "RMI Number": 1006952,
  },
  {
    Workshop: "Vag Spec Centre Randburg",
    "RMI Number": 1006611,
  },
  {
    Workshop: "Vagtec Auto Centre",
    "RMI Number": 1006891,
  },
  {
    Workshop: "Vanos Tech",
    "RMI Number": 1007090,
  },
  {
    Workshop: "Vee Motors \u2013 Workshop",
    "RMI Number": 1003992,
  },
  {
    Workshop: "Venter Gearboxes & Differentials",
    "RMI Number": 4004990,
  },
  {
    Workshop: "Vereeniging Auto Electronics",
    "RMI Number": 1007736,
  },
  {
    Workshop: "Versloot Auto Dynamics",
    "RMI Number": 4003630,
  },
  {
    Workshop: "Vib Auto Electrical Bosch",
    "RMI Number": 1005049,
  },
  {
    Workshop: "Vimla Engines Gearbox Diff & Suspen",
    "RMI Number": 4004117,
  },
  {
    Workshop: "Vision Motors",
    "RMI Number": 4003491,
  },
  {
    Workshop: "Vj Auto Clinic",
    "RMI Number": 1006105,
  },
  {
    Workshop: "Vlg Fleet Maintenance",
    "RMI Number": 1007343,
  },
  {
    Workshop: "Vom S Engineering",
    "RMI Number": 1001896,
  },
  {
    Workshop: "Vorsprung Mechanical And Auto Electrical",
    "RMI Number": 1006213,
  },
  {
    Workshop: "Vtec",
    "RMI Number": 4004888,
  },
  {
    Workshop: "V-tek Service Centre & Auto Ice",
    "RMI Number": 4002226,
  },
  {
    Workshop: "Vukonna Services And Projects",
    "RMI Number": 4005494,
  },
  {
    Workshop: "Vusumuzi Auto Services",
    "RMI Number": 1002330,
  },
  {
    Workshop: "Vuthani Motors",
    "RMI Number": 1007669,
  },
  {
    Workshop: "Vw Clinic",
    "RMI Number": 1004176,
  },
  {
    Workshop: "Wakko Performance",
    "RMI Number": 4005810,
  },
  {
    Workshop: "We Buy Cars",
    "RMI Number": 4006135,
  },
  {
    Workshop: "West Rand Diesel&auto Electrical Se",
    "RMI Number": 1001962,
  },
  {
    Workshop: "Wilber Van Dyk Motors",
    "RMI Number": 1006885,
  },
  {
    Workshop: "William Nicol Motors",
    "RMI Number": 1003400,
  },
  {
    Workshop: "Wilropark Motor Repairs Centre Cc",
    "RMI Number": 1003856,
  },
  {
    Workshop: "Wonderboom Car Service",
    "RMI Number": 4003488,
  },
  {
    Workshop: "Yellow Metal Auto Care",
    "RMI Number": 1008158,
  },
  {
    Workshop: "Zee Tech Auto",
    "RMI Number": 1007977,
  },
  {
    Workshop: "Zibondiwe Mechanical",
    "RMI Number": 1007149,
  },
  {
    Workshop: "Zilek Auto",
    "RMI Number": 1007490,
  },
  {
    Workshop: "Zone Offroad Products Jhb",
    "RMI Number": 1005744,
  },
  {
    Workshop: "Zoran Auto",
    "RMI Number": 1006748,
  },
];
